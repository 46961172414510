import React, { Component } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import WhiteButton from './buttons/WhiteButton'
import { ModalContext } from './WrapPageElement'

export class Nav extends Component {
  constructor(props) {
    super(props)
    this.toggleClass = this.toggleClass.bind(this)
    this.state = {
      active: false,
    }
  }
  toggleClass() {
    const currentState = this.state.active
    if (this.props.displayDownloadForm) {
      this.setState({ active: false })
    } else {
      this.setState({ active: !currentState })
    }
  }

  handleArrows(e) {
    if ([38, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault()
    }
    let prev = 0
    const menu = this.props.data.data.allWordpressWpApiMenusMenusItems.edges[0]
      .node.items

    menu.find(menuParentItem => {
      if (menuParentItem.wordpress_children) {
        menuParentItem.wordpress_children.find(menuChildItem => {
          if (
            this.props.data.data.wordpressPage.slug == menuChildItem.object_slug
          ) {
            prev = menuChildItem
          }
        })
      }
      if (prev < 1) {
        if (
          this.props.data.data.wordpressPage.slug == menuParentItem.object_slug
        ) {
          prev = menuParentItem
        }
      }
    })
    let currentPage = prev.order || 0
    let previous_page = { object_slug: '/' }
    let next_page = 'last-page'
    menu.find(menuItem => {
      if (menuItem.wordpress_children) {
        menuItem.wordpress_children.find(menuChildItem => {
          if (menuChildItem.order === currentPage - 1) {
            previous_page = menuChildItem
          }
          if (menuChildItem.order === currentPage + 1) {
            next_page = menuChildItem
          }
        })
      }

      //if home page disallow up arrow
      if (currentPage === 0) {
        previous_page = '--start--'
      } else if (
        /*
             else if(menuItem.order === currentPage-2){
                previous_page = menuItem;
                console.log('yes');
            }
*/
        menuItem.order ===
        currentPage - 1 /* && !menuItem.wordpress_children */
      ) {
        previous_page = menuItem
      }
      // set Next page
      if (menuItem.order === currentPage + 1) {
        next_page = menuItem
      }
    })

    if (e.keyCode === 38) {
      if (previous_page !== '--start--') {
        navigate(previous_page.object_slug, {
          state: {
            previous_order: currentPage,
            order_scroll: previous_page.order,
          },
        })
      }
    } else if (e.keyCode === 40) {
      if (next_page !== 'last-page') {
        navigate(next_page.object_slug, {
          state: { previous_order: currentPage, order_scroll: next_page.order },
        })
      }
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleArrows.bind(this))
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleArrows.bind(this))
  }

  handleLinkedinShare = () => {
    const currentUrl = encodeURI(this.props.data.location.href)
    return window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&title=MMC%20Ventures&summary=MMC%20Ventures%20presents%20The%20AI%20Playbook&source=`,
      'sharer',
      'toolbar=0,status=0,width=548,height=325'
    )
  }
  handleTwitterShare = () => {
    const currentUrl = encodeURI(this.props.data.location.href)
    return window.open(
      `https://twitter.com/intent/tweet?url=${currentUrl}&text=MMC%20Ventures&summary=MMC%20Ventures%20presents%20The%20AI%20Playbook`,
      'sharer',
      'toolbar=0,status=0,width=548,height=325'
    )
  }
  handleFacebookShare = () => {
    const currentUrl = encodeURI(this.props.data.location.href)
    return window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`,
      'sharer',
      'toolbar=0,status=0,width=548,height=325'
    )
  }
  render() {
    const pages_ = this.props.data.data
    let pages = null
    if (pages_) {
      pages = pages_.allWordpressWpApiMenusMenusItems.edges[0].node.items
    }
    return (
      <nav
        style={{
          position: 'absolute',
          zIndex: '990',
        }}
      >
        <div className={'main-nav' + (this.state.active ? ' open' : ' closed')}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>The State of AI 2019: Divergence</h1>
              </div>

              <div className="col-md-6">
                <ul className="chapters">
                  {pages &&
                    pages.map(item => (
                      <li key={item.object_slug}>
                        <Link
                          activeClassName="active"
                          to={item.object_slug}
                          onClick={this.toggleClass}
                          state={{
                            order_scroll: item.order,
                            transition: 'fade',
                          }}
                        >
                          {item.title}
                        </Link>

                        <ul>
                          {item.wordpress_children &&
                            item.wordpress_children.map(subitem => (
                              <li key={subitem.object_slug}>
                                <Link
                                  onClick={this.toggleClass}
                                  activeClassName="active"
                                  to={subitem.object_slug}
                                  state={{
                                    order_scroll: subitem.order,
                                    transition: 'fade',
                                  }}
                                >
                                  {subitem.title}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="col-md-6 right-nav">
                <ModalContext.Consumer>
                  {func => (
                    <WhiteButton
                      onCloseNav={this.toggleClass}
                      onShowModal={func}
                      label="Download the report"
                      prev={1}
                      order={1}
                    />
                  )}
                </ModalContext.Consumer>
                <ul className="chapters">
                  <li style={{ fontSize: '1.7em' }}>
                    <p>
                      <strong>Share</strong>
                    </p>
                    <a onClick={this.handleLinkedinShare}>
                      <i
                        className="fab fa-linkedin"
                        style={{ marginRight: 20 }}
                      />
                    </a>
                    <a onClick={this.handleTwitterShare}>
                      <i
                        className="fab fa-twitter-square"
                        style={{ marginRight: 20 }}
                      />
                    </a>
                    <a onClick={this.handleFacebookShare}>
                      <i className="fab fa-facebook-square" />
                    </a>
                  </li>
                  <li>
                    <Link
                      onClick={this.toggleClass}
                      activeClassName="active"
                      state={{
                        order_scroll: 0,
                        transition: 'fade',
                      }}
                      to="/about-mmc-ventures"
                    >
                      About MMC Ventures
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.toggleClass}
                      activeClassName="active"
                      state={{
                        order_scroll: 0,
                        transition: 'fade',
                      }}
                      to="/about-barclays-uk-ventures"
                    >
                      About Barclays UK Ventures
                    </Link>
                  </li>
                  <li>
                    <a href="https://mmc.vc/team" target="_blank">
                      Contact MMC Ventures
                    </a>
                  </li>
                  <li>
                    <Link
                      onClick={this.toggleClass}
                      activeClassName="active"
                      state={{
                        order_scroll: 0,
                        transition: 'fade',
                      }}
                      to="/terms-and-conditions"
                    >
                      Terms and conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.toggleClass}
                      activeClassName="active"
                      state={{
                        order_scroll: 0,
                        transition: 'fade',
                      }}
                      to="/cookie-policy"
                    >
                      Cookie Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={'navbar' + (this.state.active ? ' is-active' : '')}>
          <button
            className={
              'hamburger hamburger--slider' +
              (this.state.active ? ' is-active' : '')
            }
            onClick={this.toggleClass}
            type="button"
            aria-label="Menu"
            aria-controls="navigation"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
            <span className="text">Menu</span>
          </button>

          <div className="navTextWrap">
            <span className="nav-text">
              {pages_ &&
              typeof pages_.wordpressPage != 'undefined' &&
              pages_.wordpressPage.title != 'Home'
                ? pages_.wordpressPage.title
                : 'Sections'}
            </span>
            <Link
              to="/"
              style={{
                color: 'white',
                textDecoration: 'none',
              }}
            />
          </div>

          <div
            className="nav-social"
            style={{
              fontSize: '2em',
              position: 'absolute',
              bottom: 0,
              left: 25,
              zIndex: 999,
            }}
          >
            <p style={{ fontSize: '.4em' }}>Share</p>
            <p>
              <a onClick={this.handleLinkedinShare}>
                <i className="fab fa-linkedin" />
              </a>
            </p>
            <p>
              <a onClick={this.handleTwitterShare}>
                <i className="fab fa-twitter-square" />
              </a>
            </p>
            <p>
              <a onClick={this.handleFacebookShare}>
                <i className="fab fa-facebook-square" />
              </a>
            </p>
          </div>
        </div>
      </nav>
    )
  }
}

export default Nav
